@charset "utf-8";

@font-face {
  font-family: "Safran";
  src: url("safrandeval.woff") format("woff"),
       url("safrandeval.woff2") format("woff2");
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

// Define defaults for each variable.

$safran-font-family: "Safran";
$base-font-family: 'Poppins', BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 200 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$main-color:       rgb(147, 41, 41) !default;
$nav-color: rgb(147, 41, 41);
$bright-color:       #ebb6b6 !default;
$secondary-color:  #fff3e8 !default;
$text-color:       rgb(147, 41, 41) !default;
$background-color: $secondary-color !default;
$content-color:    $secondary-color !default;
$brand-color:      $secondary-color !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1000px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
