/**
 * Site header
 */
.site-header {
  min-height: $spacing-unit * 1.865;
  // Positioning context for the mobile navigation icon
  position: relative;
  background-color: $background-color;
  
}

.banner-wrapper {
  text-align: center;
  padding-top: $spacing-unit * 1.5;
  padding-bottom: $spacing-unit * 1.5;
  background-image: url("../assets/images/top-low.png");
  background-size: 1000px;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
  
}

.site-title {
  @include relative-font-size(1.625);
  font-family: "Safran";
  font-size: 100px;
  line-height: $base-line-height * $base-font-size * 6;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.306);
  
  color: white;
  &,
  &:visited {
    color: white;
  }
}

@media (min-width: 100px) {
  .site-title { font-size: 20px }
}

@media (min-width: 300px) {
  .site-title { font-size: 100px }
}

.site-nav {
  line-height: $base-line-height * $base-font-size * 2;
  text-align: center;
  background-color: $nav-color;
  font-family: "Poppins";

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: white;
    font-weight: 100;
    line-height: $base-line-height;
    position: relative;
    overflow: hidden;
    background: linear-gradient(to right, white, white 50%, $bright-color 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

.page-link:hover {
  background-position: 0 100%;
}

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit /4 0;
  background-color: $nav-color;
  font-family: "Poppins" ;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $bright-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
  text-align: center;
}

.footer-col {
  float: left;
  margin-top: 20px;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-2 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
  background-color: $content-color;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(4);
  line-height: 1;
  text-align: center;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

.first-column {
  width: 60%;
  float: left;
  margin-top: 5%;
}

.second-column {
  width: 40%;
  float: right;
  text-align: center;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Gallery */

$num-of-tabs: 4;

@mixin tabs {
  @for $i from 1 through $num-of-tabs {
    &:nth-of-type(#{$i}) {
      &:checked {
        ~ .slide {
          left: calc((100% / #{$num-of-tabs}) * #{$i - 1});
        }
      }
    }
  }
}


.tab-wrap {
  width: 100%;
  position: relative;
  display: flex;
  height: 100%;
}

input[type="radio"][name="tabs"] {
  position: absolute;
  z-index: -1;
  &:checked {
    + .tab-label-content {
      label {
        color: $main-color;
        font-weight: 400;
      }
      .tab-content {
        width: 100%;
        display: block;
        
      }
    }
  }
  @include tabs;
  &:first-of-type {
    &:checked {
      ~ .slide {
        left: 0;
      }
    }
  }
}

.gridlabel {
  cursor: pointer;
  color: $main-color;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 56px;
  transition: color 0.2s ease;
  width: 100%;
}

.slide {
  background: $main-color;
  width: calc(100% / #{$num-of-tabs});
  height: 1px;
  position: absolute;
  left: 0;
  top: calc(0% + 50px);
  transition: left 0.3s ease-out;
}

.tab-label-content {
  width: 100%;
  .tab-content {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 500px; /* Need a specific value to work */
    line-height: 130%;
    top: 100px;
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .tab-wrap {
    width: 80%;
    
  }
  .tab-label-content {
    .tab-content {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      width: 500px; /* Need a specific value to work */
      line-height: 130%;
      top: 150px;
      display: none;
    }
  }

  .gridlabel {
    width: 100%;
  }
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(230px, auto);
}
.one {
  grid-column: 1;
  grid-row: 1;
  margin: auto;
}
.two {
  grid-column: 2;
  grid-row: 1;
  margin: auto;
}
.three {
  grid-column: 3;
  grid-row:1;
  margin: auto;
}
.four {
  grid-column: 1;
  grid-row: 2;
  margin: auto;
}
.five {
  grid-column: 2;
  grid-row: 2;
  margin: auto;
}
.six {
  grid-column: 3;
  grid-row: 2;
  margin: auto;
}
.seven {
  grid-column: 1;
  grid-row: 3;
  margin: auto;
}
.eight {
  grid-column: 2;
  grid-row: 3;
  margin: auto;
}
.nine {
  grid-column: 3;
  grid-row: 3;
  margin: auto;
}

.gallery-image {
  width: 175px;
  border-radius: 15px;
  margin: auto;
  filter: drop-shadow(0px 0px 2px $nav-color);
  transition: transform .2s; /* Animation */
  text-align: center;
}

.gallery-image img{
  border-radius: 15px;
}

.gallery-image:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.gallery-title {
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.modal-title {
  text-align: center;
}

.modal-separator {
  background-color: $text-color;
  margin-bottom: 1em;
  height: 1px;
}

.modal-price {
  font-weight: 600;
}

.body-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #222;
  position: relative;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
  padding: 1.3rem;
  max-width: 500px;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  z-index: 2;
  top:-80px;
  filter: drop-shadow(0px 0px 5px rgba(147, 41, 41, 0.4));
}

.modal .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal p {
  font-size: 1.1rem;
  color: #777;
  margin: 0.4rem;
}

button {
  cursor: pointer;
  border: none;
  font-weight: 600;
}

.btn {
  display: inline-block;
  padding: 0.8rem 1.4rem;
  font-weight: 700;
  background-color: black;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 1em;
}

.btn-open {
  position: absolute;
  bottom: 150px;
}

.btn-close {
  font-size: 1em;
  margin-top: 2rem;
  color: white;
  width: 2em;
  text-align: center;
  border-radius: 50%;
  background-color: $text-color;
}

.hidden {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
}
